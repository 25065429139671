html,
body,
#root {
  height: 100%
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: Menlo, Monaco, "SFMono-Regular", Consolas, "Liberation Mono", Courier, monospace;
  background-color: #f9f2f4;
  padding-left: 4px;
  padding-right: 4px;
  padding-top: 2px;
  padding-bottom: 2px;
  color: #c7254e;
  border-radius: 4px;
}
/**/

#main-content-layout{
    overflow-x: hidden;
}

.full-screen-bg {
    background-color: #f5f5f5;
    width: 100%;
    height: 100%;
}


hr {
    opacity: 0.4;
}

.unselectable-span {
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.hide-on-demand {
    display: none !important;
}

.code-input {
    font-family: Menlo, Monaco, "SFMono-Regular", Consolas, "Liberation Mono", Courier, monospace !important;
}

.netdata-field {
    margin-bottom: 20px;
}

.logs-output {
    font-family: Menlo, Monaco, "SFMono-Regular", Consolas, "Liberation Mono", Courier, monospace !important;
    overflow: scroll;
    height: 250px;
    border: 2px solid #cccccc;
    background-color: #f6f6f6;
    border-radius: 5px;
    padding: 10px;
    font-size: 90%;
}
